import React, { createContext, useState, useContext } from "react";

const ActiveHospitalLocationContext = createContext();

export const ActiveHospitalLocationProvider = ({ children }) => {
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeSystem, setActiveSystem] = useState(null);
  const [activeLocationId, setActiveLocationId] = useState(null);
  const [activeSystemId, setActiveSystemId] = useState(null);

  const setActiveHospitalLocation = (location) => {
    setActiveLocation(location);
    setActiveLocationId(location?.LocationID || null);
    if (location?.SystemID) {
      setActiveSystemId(location.SystemID);
    }
  };

  const setActiveHospitalSystem = (system) => {
    setActiveSystem(system);
    setActiveSystemId(system?.SystemID || null);
    // Clear location when changing system
    if (!system) {
      setActiveLocation(null);
      setActiveLocationId(null);
    }
  };

  return (
    <ActiveHospitalLocationContext.Provider
      value={{
        activeLocation,
        setActiveHospitalLocation,
        activeSystem,
        setActiveHospitalSystem,
        activeLocationId,
        activeSystemId,
      }}
    >
      {children}
    </ActiveHospitalLocationContext.Provider>
  );
};

export const useActiveHospitalLocation = () =>
  useContext(ActiveHospitalLocationContext);
