import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { useUser } from "./UserContext";
import { useChargeSheet } from "./ChargeSheetContext";
import { API_URL } from "../config";

const STORAGE_KEY = 'activeChargeSheetDetails';

const ChargeSheetDetailsContext = createContext({
  activeChargeSheetDetails: [],
  updateChargeSheetDetails: () => {},
  addChargeSheetDetail: () => {},
  loading: false,
  error: null,
  refreshDetails: () => {},
  clearChargeSheetDetails: () => {}
});

export const ChargeSheetDetailsProvider = ({ children }) => {
  const [activeChargeSheetDetails, setActiveChargeSheetDetails] = useState(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      return stored ? JSON.parse(stored) : [];
    } catch (e) {
      console.error('Error reading charge sheet details from localStorage:', e);
      return [];
    }
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { activeChargeSheet } = useChargeSheet();
  const { user, token } = useUser();

  const normalizeChargeDetails = (details) => {
    if (!Array.isArray(details)) {
      console.error('Invalid details format:', details);
      return [];
    }

    return details.map(detail => {
      // Common fields that should be present in all charge objects
      const normalizedCharge = {
        ChargeID: detail.ChargeID,
        ServiceDescription: detail.ServiceDescription,
        BillingCode: detail.BillingCode,
        LocationID: detail.LocationID,
        LocationName: detail.LocationName,
        SystemID: detail.SystemID,
        GrossCharge: detail.GrossCharge,
        DiscountedCashPrice: detail.DiscountedCashPrice,
        DeidentifiedMinimumCharge: detail.DeidentifiedMinimumCharge,
        DeidentifiedMaximumCharge: detail.DeidentifiedMaximumCharge,
        year: detail.year || detail.Year,
      };

      // Handle Endeavor Health format (SystemID: 3)
      if (detail.SystemID === 3) {
        normalizedCharge.insurancePrices = {
          Aetna: { IP: detail.AetnaIP, OP: detail.AetnaOP },
          BlueCrossBlueShield: { IP: detail.BlueCrossBlueShieldIP, OP: detail.BlueCrossBlueShieldOP },
          Cigna: { IP: detail.CignaIP, OP: detail.CignaOP },
          Humana: { IP: detail.HumanaIP, OP: detail.HumanaOP },
          UnitedHealthcare: { IP: detail.UnitedHealthcareIP, OP: detail.UnitedHealthcareOP },
          MedicaidMedicaidMCO: { IP: detail.MedicaidMedicaidMCOIP, OP: detail.MedicaidMedicaidMCOOP },
          MedicareMedicareAdvantage: { IP: detail.MedicareMedicareAdvantageIP, OP: detail.MedicareMedicareAdvantageOP },
        };
      }
      // Handle Northwestern Medicine format (SystemID: 4)
      else if (detail.SystemID === 4) {
        normalizedCharge.insurancePrices = {
          Aetna: {
            Standard: detail.AETNA,
            APCNSP: detail.AETNAAPCNSP,
            BP: detail.AETNABP,
            ILPreferred: detail.AETNAILPREFERRED,
            NAP: detail.AETNANAP,
          },
          BlueCrossBlueShield: {
            BlueChoiceOptions: detail.BCBSBLUECHOICEOPTIONS,
            BlueChoicePreferred: detail.BCBSBLUECHOICEPREFERRED,
            PPO: detail.BCBSPPO,
          },
          Cigna: {
            Standard: detail.CIGNA,
            Alternative: detail.CIGNAALTERNATIVE,
            Broad: detail.CIGNABROAD,
            Plus: detail.CIGNAPLUS,
            PlusNM: detail.CIGNAPLUSNM,
          },
          UnitedHealthcare: {
            Core: detail.UHCCORE,
            HMOPPO: detail.UHCHMOPPO,
            PPO: detail.UHCPPO,
            AllOther: detail.UHCALLOTHER,
          },
        };
      }
      // Handle RUSH format (SystemID: 5)
      else if (detail.SystemID === 5) {
        normalizedCharge.insurancePrices = {
          Aetna: {
            PPO: detail.PPOAETNA501014,
            HMO: detail.HMOAETNAOPENACCESS501010,
            Signature: detail.AetnaSignatureAdministratos501021,
            StateOfIllinois: detail.AetnaStateOfIllinoisPPOHMOOAP501110,
          },
          BlueCrossBlueShield: {
            PPO: detail.PPOILLINOISBC510133,
            HMO: detail.BLUECHOICEHMOBCOFILLINOIS510131,
            BlueChoice: detail.BLUECHOICEPPOBCOFILLINOIS510144,
          },
          Cigna: {
            Network: detail.HMONETWORKCIGNA517010,
            OneHealth: detail.CIGNAONEHEALTHHMO517030,
            Employees: detail.CIGNACTAEMPLOYEES517025,
          },
          UnitedHealthcare: {
            Core: detail.UNITEDCORENAVIGATE582021,
            Choice: detail.PPOUNITEDHEALTHCARECHOICESELECTOPTION582010,
            Affiliates: detail.UHCAFFILIATES582025,
          },
        };
      }

      return normalizedCharge;
    });
  };

  const updateChargeSheetDetails = useCallback((details) => {
    const normalizedDetails = normalizeChargeDetails(details);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(normalizedDetails));
    setActiveChargeSheetDetails(normalizedDetails);
  }, []);

  const addChargeSheetDetail = useCallback((newDetail) => {
    setActiveChargeSheetDetails(prev => {
      const updatedDetails = Array.isArray(prev) ? [...prev, newDetail] : [newDetail];
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedDetails));
      return updatedDetails;
    });
  }, []);

  const clearChargeSheetDetails = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    setActiveChargeSheetDetails([]);
  }, []);

  // Clear details when user logs out
  useEffect(() => {
    if (!user) {
      clearChargeSheetDetails();
      setError(null);
    }
  }, [user, clearChargeSheetDetails]);

  // Fetch details when active charge sheet changes
  const fetchActiveChargeSheetDetails = useCallback(async () => {
    if (!activeChargeSheet?.UserChargeSheetID || !user?.id || !token) {
      console.log('Missing required data for fetch:', {
        chargeSheetId: activeChargeSheet?.UserChargeSheetID,
        userId: user?.id
      });
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/api/get-chargesheet-charges`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userID: user.id,
          userChargeSheetID: activeChargeSheet.UserChargeSheetID
        })
      });

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }

      const data = await response.json();
      if (Array.isArray(data)) {
        const normalizedData = normalizeChargeDetails(data);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(normalizedData));
        setActiveChargeSheetDetails(normalizedData);
      } else {
        throw new Error('Invalid data format received from server');
      }
    } catch (err) {
      console.error('Error fetching charge sheet details:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [activeChargeSheet?.UserChargeSheetID, user?.id, token]);

  useEffect(() => {
    fetchActiveChargeSheetDetails();
  }, [fetchActiveChargeSheetDetails]);

  return (
    <ChargeSheetDetailsContext.Provider value={{
      activeChargeSheetDetails,
      updateChargeSheetDetails,
      addChargeSheetDetail,
      loading,
      error,
      refreshDetails: fetchActiveChargeSheetDetails,
      clearChargeSheetDetails
    }}>
      {children}
    </ChargeSheetDetailsContext.Provider>
  );
};

ChargeSheetDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useChargeSheetDetails = () => useContext(ChargeSheetDetailsContext);

export default ChargeSheetDetailsProvider;
