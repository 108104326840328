import React, { createContext, useState, useContext } from "react";

// Define default context structure and functions to prevent runtime errors outside the provider
const defaultContext = {
  overlayState: {
    isOpen: false,
    activeTab: "notepad",
  },
  setOverlayState: () => {}, // Default empty function to prevent crashes when context is not wrapped with a provider
};

// Create the OverlayContext
export const OverlayContext = createContext(defaultContext);

// OverlayProvider component to manage and provide overlay state
export const OverlayProvider = ({ children }) => {
  const [overlayState, setOverlayState] = useState({
    isOpen: false,
    activeTab: "notepad",
  });

  return (
    <OverlayContext.Provider value={{ overlayState, setOverlayState }}>
      {children}
    </OverlayContext.Provider>
  );
};

export const useOverlay = () => useContext(OverlayContext);
