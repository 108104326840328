import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from "react";
import { useUser } from "./UserContext";
import useChargeSheets from "../hooks/useChargeSheets";

const STORAGE_KEY = 'activeChargeSheet';
const STORAGE_SEARCH_QUERY_KEY = 'chargeSheetSearchQuery';
const STORAGE_FILTERS_KEY = 'chargeSheetFilters';

// Define the context with default values
const ChargeSheetContext = createContext({
  chargeSheets: [],
  activeChargeSheet: null,
  setActiveChargeSheetInfo: () => {},
  clearActiveChargeSheet: () => {},
  loading: false,
  error: null,
  fetchChargeSheets: () => {},
  searchQuery: '',
  setSearchQuery: () => {},
  filters: {},
  setFilters: () => {},
  filteredChargeSheets: [],
  clearFilters: () => {}
});

export const ChargeSheetProvider = ({ children }) => {
  const { user } = useUser();
  
  const { 
    chargeSheets, 
    loading: chargeSheetLoading, 
    error: chargeSheetError, 
    fetchChargeSheets 
  } = useChargeSheets();
  
  // Initialize states from localStorage with proper type checking
  const [activeChargeSheet, setActiveChargeSheet] = useState(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      if (!stored) {
        console.log('[ChargeSheetContext] No activeChargeSheet in localStorage');
        return null;
      }
      
      const parsed = JSON.parse(stored);
      // Validate the parsed data has required fields
      if (parsed && typeof parsed.UserChargeSheetID === 'number') {
        console.log('[ChargeSheetContext] Loaded activeChargeSheet from localStorage:', parsed);
        return parsed;
      }
      console.log('[ChargeSheetContext] Invalid activeChargeSheet in localStorage, clearing');
      localStorage.removeItem(STORAGE_KEY);
      return null;
    } catch (e) {
      console.error('[ChargeSheetContext] Error reading from localStorage:', e);
      localStorage.removeItem(STORAGE_KEY);
      return null;
    }
  });

  const [searchQuery, setSearchQuery] = useState(() => {
    try {
      const query = localStorage.getItem(STORAGE_SEARCH_QUERY_KEY) || '';
      return query;
    } catch (e) {
      return '';
    }
  });

  const [filters, setFilters] = useState(() => {
    try {
      const stored = localStorage.getItem(STORAGE_FILTERS_KEY);
      return stored ? JSON.parse(stored) : {};
    } catch (e) {
      return {};
    }
  });

  // Define callbacks outside of useMemo
  const handleSetActiveChargeSheet = useCallback((chargeSheet) => {
    if (!chargeSheet || typeof chargeSheet.UserChargeSheetID !== 'number') {
      console.log('[ChargeSheetContext] Invalid chargeSheet provided:', chargeSheet);
      return;
    }
    
    const normalizedChargeSheet = {
      UserChargeSheetID: chargeSheet.UserChargeSheetID,
      ChargeSheetNameDefault: chargeSheet.ChargeSheetNameDefault || 'Untitled',
      CreatedDate: chargeSheet.CreatedDate,
      LastModifiedDate: chargeSheet.LastModifiedDate,
      UserID: chargeSheet.UserID
    };
    
    console.log('[ChargeSheetContext] Setting activeChargeSheet:', normalizedChargeSheet);
    setActiveChargeSheet(normalizedChargeSheet);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(normalizedChargeSheet));
  }, []);

  const handleClearActiveChargeSheet = useCallback(() => {
    setActiveChargeSheet(null);
    localStorage.removeItem(STORAGE_KEY);
    console.log('[ChargeSheetContext] Cleared activeChargeSheet');
  }, []);

  const handleSetSearchQuery = useCallback((query) => {
    console.log('[ChargeSheetContext] Updating search query:', query);
    localStorage.setItem(STORAGE_SEARCH_QUERY_KEY, query);
    setSearchQuery(query);
  }, []);

  const handleSetFilters = useCallback((newFilters) => {
    console.log('[ChargeSheetContext] Updating filters:', newFilters);
    localStorage.setItem(STORAGE_FILTERS_KEY, JSON.stringify(newFilters));
    setFilters(newFilters);
  }, []);

  const handleClearFilters = useCallback(() => {
    console.log('[ChargeSheetContext] Clearing all filters');
    localStorage.removeItem(STORAGE_FILTERS_KEY);
    localStorage.removeItem(STORAGE_SEARCH_QUERY_KEY);
    setFilters({});
    setSearchQuery('');
  }, []);

  // Fetch charge sheets when user changes
  useEffect(() => {
    if (user?.id) {
      console.log('[ChargeSheetContext] User changed, fetching charge sheets:', user.id);
      fetchChargeSheets();
    }
  }, [user?.id, fetchChargeSheets]);

  // Clear active charge sheet if it doesn't exist in the current charge sheets
  useEffect(() => {
    if (chargeSheets.length > 0 && activeChargeSheet) {
      const activeSheetExists = chargeSheets.some(
        sheet => sheet.UserChargeSheetID === activeChargeSheet.UserChargeSheetID
      );
      if (!activeSheetExists) {
        console.log('[ChargeSheetContext] Active charge sheet not found in current charge sheets, clearing');
        handleClearActiveChargeSheet();
      }
    }
  }, [chargeSheets, activeChargeSheet, handleClearActiveChargeSheet]);

  // Memoize filtered charge sheets
  const filteredSheets = useMemo(() => {
    console.log('[ChargeSheetContext] Filtering chargeSheets:', {
      totalChargeSheets: chargeSheets?.length || 0,
      searchQuery,
      filters
    });
    return filterChargeSheets(chargeSheets, searchQuery, filters);
  }, [chargeSheets, searchQuery, filters]);

  // Memoize the context value
  const value = useMemo(() => ({
    chargeSheets,
    activeChargeSheet,
    setActiveChargeSheetInfo: handleSetActiveChargeSheet,
    clearActiveChargeSheet: handleClearActiveChargeSheet,
    loading: chargeSheetLoading,
    error: chargeSheetError,
    fetchChargeSheets,
    searchQuery,
    setSearchQuery: handleSetSearchQuery,
    filters,
    setFilters: handleSetFilters,
    filteredChargeSheets: filteredSheets,
    clearFilters: handleClearFilters
  }), [
    chargeSheets,
    activeChargeSheet,
    handleSetActiveChargeSheet,
    handleClearActiveChargeSheet,
    chargeSheetLoading,
    chargeSheetError,
    fetchChargeSheets,
    searchQuery,
    handleSetSearchQuery,
    filters,
    handleSetFilters,
    filteredSheets,
    handleClearFilters
  ]);

  return (
    <ChargeSheetContext.Provider value={value}>
      {children}
    </ChargeSheetContext.Provider>
  );
};

// Custom hook to use the ChargeSheetContext
export const useChargeSheet = () => {
  const context = useContext(ChargeSheetContext);
  if (!context) {
    throw new Error('useChargeSheet must be used within a ChargeSheetProvider');
  }
  return context;
};

export default ChargeSheetProvider;

// Helper function to filter charge sheets
const filterChargeSheets = (chargeSheets, searchQuery, filters) => {
  if (!chargeSheets) return [];

  return chargeSheets.filter(sheet => {
    // Search query filter
    const searchLower = searchQuery.toLowerCase();
    const matchesSearch = !searchQuery || 
      sheet.ChargeSheetNameDefault?.toLowerCase().includes(searchLower) ||
      sheet.UserChargeSheetID?.toString().includes(searchLower);

    // Apply all active filters
    const matchesFilters = Object.entries(filters).every(([key, value]) => {
      if (!value) return true;
      return sheet[key] === value;
    });

    return matchesSearch && matchesFilters;
  });
};
