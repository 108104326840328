import React from 'react';
import PropTypes from 'prop-types';

const variants = {
  h1: 'text-4xl font-bold',
  h2: 'text-3xl font-bold',
  h3: 'text-2xl font-bold',
  h4: 'text-xl font-semibold',
  h5: 'text-lg font-semibold',
  body1: 'text-base',
  body2: 'text-sm',
  caption: 'text-xs',
};

const Text = ({
  variant = 'body1',
  component,
  className = '',
  children,
  ...props
}) => {
  const Component = component || {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    body1: 'p',
    body2: 'p',
    caption: 'span',
  }[variant];

  const baseClasses = `
    text-secondary-100
    ${variants[variant]}
    ${className}
  `;

  return (
    <Component className={baseClasses} {...props}>
      {children}
    </Component>
  );
};

Text.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'body1', 'body2', 'caption']),
  component: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Text;
