import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import theme from '../../theme/theme';

const Card = ({
  children,
  className = '',
  isHoverable = false,
  isInteractive = false,
  ...props
}) => {
  const baseClasses = `
    ${theme.components.card.base}
    ${isHoverable ? 'hover:border-teal-500/30 transition-all duration-200' : ''}
    ${isInteractive ? 'cursor-pointer' : ''}
    ${className}
  `;

  return (
    <motion.div
      className={baseClasses}
      whileHover={isInteractive ? { scale: 1.01, y: -2 } : {}}
      whileTap={isInteractive ? { scale: 0.99 } : {}}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isHoverable: PropTypes.bool,
  isInteractive: PropTypes.bool,
};

export default Card;
