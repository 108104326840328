import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import theme from './theme';

const ThemeContext = createContext(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children, overrides = {} }) => {
  const mergedTheme = useMemo(() => {
    // Deep merge the default theme with any overrides
    const deepMerge = (target, source) => {
      const result = { ...target };
      for (const key in source) {
        if (source[key] instanceof Object && key in target) {
          result[key] = deepMerge(target[key], source[key]);
        } else {
          result[key] = source[key];
        }
      }
      return result;
    };

    return deepMerge(theme, overrides);
  }, [overrides]);

  return (
    <ThemeContext.Provider value={mergedTheme}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  overrides: PropTypes.object,
};

export default ThemeProvider;
