import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import Typography from './components/ui/Typography';
import Button from './components/ui/Button';
import Card from './components/ui/Card';

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const getErrorMessage = () => {
    if (error?.status === 404) {
      return "The page you're looking for doesn't exist.";
    }
    if (error?.message) {
      return error.message;
    }
    return "We apologize, but something went wrong. Please try again later.";
  };

  return (
    <div className="min-h-screen bg-gray-900 relative overflow-hidden">
      {/* Background with overlay and effects */}
      <div className="fixed inset-0 bg-cover bg-center bg-no-repeat z-0"
           style={{ 
             backgroundImage: "url('/backgroundLogo.png')",
             backgroundAttachment: "fixed",
             backgroundSize: "100% 100vh"
           }}>
        <div className="fixed inset-0 bg-gradient-to-br from-gray-900/95 via-gray-900/90 to-gray-900/95 backdrop-blur-sm" />
      </div>
      <div className="fixed inset-0 bg-grid-white/[0.02] bg-[size:60px_60px]" />

      {/* Content */}
      <div className="relative z-10 min-h-screen flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-lg px-4"
        >
          <Card>
            <div className="p-8 text-center">
              <div className="mb-6">
                <motion.div
                  initial={{ scale: 0.5 }}
                  animate={{ scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                  className="w-24 h-24 rounded-full bg-red-500/10 flex items-center justify-center mx-auto"
                >
                  <FontAwesomeIcon 
                    icon={faExclamationTriangle} 
                    className="text-red-400 text-4xl"
                  />
                </motion.div>
              </div>

              <Typography variant="h2" className="text-red-400 mb-4">
                Oops! Something went wrong
              </Typography>

              <Typography variant="body1" className="text-gray-300 mb-8">
                {getErrorMessage()}
              </Typography>

              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <Button
                  variant="outline"
                  size="lg"
                  leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </Button>
                <Button
                  variant="primary"
                  size="lg"
                  leftIcon={<FontAwesomeIcon icon={faHome} />}
                  onClick={() => navigate('/')}
                >
                  Home
                </Button>
              </div>
            </div>
          </Card>
        </motion.div>
      </div>
    </div>
  );
};

export default ErrorPage;