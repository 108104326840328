import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faArrowLeft, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import Typography from './components/ui/Typography';
import Button from './components/ui/Button';
import Card from './components/ui/Card';
import Toast from './components/ui/Toast';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      showToast: false
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      showToast: true
    });

    // Call the onError callback if provided
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }

    // Log the error to your error reporting service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  handleReset = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
      showToast: false
    });
  };

  render() {
    if (this.state.hasError) {
      const fallback = this.props.fallback || (
        <div className="min-h-screen bg-gray-900 relative overflow-hidden">
          {/* Background with overlay and effects */}
          <div className="fixed inset-0 bg-cover bg-center bg-no-repeat z-0"
               style={{ 
                 backgroundImage: "url('/backgroundLogo.png')",
                 backgroundAttachment: "fixed",
                 backgroundSize: "100% 100vh"
               }}>
            <div className="fixed inset-0 bg-gradient-to-br from-gray-900/95 via-gray-900/90 to-gray-900/95 backdrop-blur-sm" />
          </div>
          <div className="fixed inset-0 bg-grid-white/[0.02] bg-[size:60px_60px]" />

          {/* Content */}
          <div className="relative z-10 min-h-screen flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="w-full max-w-lg px-4"
            >
              <Card>
                <div className="p-8 text-center">
                  <div className="mb-6">
                    <motion.div
                      initial={{ scale: 0.5 }}
                      animate={{ scale: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                      }}
                      className="w-24 h-24 rounded-full bg-red-500/10 flex items-center justify-center mx-auto"
                    >
                      <FontAwesomeIcon 
                        icon={faExclamationTriangle} 
                        className="text-red-400 text-4xl"
                      />
                    </motion.div>
                  </div>

                  <Typography variant="h2" className="text-red-400 mb-4">
                    Component Error
                  </Typography>

                  <Typography variant="body1" className="text-gray-300 mb-8">
                    {this.state.error?.message || 'An unexpected error occurred.'}
                  </Typography>

                  <div className="flex flex-col sm:flex-row justify-center gap-4">
                    <Button
                      variant="outline"
                      size="lg"
                      leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => window.history.back()}
                    >
                      Go Back
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      leftIcon={<FontAwesomeIcon icon={faRotateRight} />}
                      onClick={this.handleReset}
                    >
                      Try Again
                    </Button>
                  </div>

                  {process.env.NODE_ENV === 'development' && this.state.errorInfo && (
                    <div className="mt-8 text-left">
                      <Typography variant="h3" className="text-gray-300 mb-2">
                        Error Details
                      </Typography>
                      <pre className="bg-gray-800/30 p-4 rounded-lg overflow-x-auto text-sm text-gray-300">
                        {this.state.errorInfo.componentStack}
                      </pre>
                    </div>
                  )}
                </div>
              </Card>
            </motion.div>
          </div>
        </div>
      );

      return (
        <>
          {fallback}
          <AnimatePresence>
            {this.state.showToast && (
              <Toast
                type="error"
                message="An error occurred in the application"
                onClose={() => this.setState({ showToast: false })}
              />
            )}
          </AnimatePresence>
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.element,
  onError: PropTypes.func
};

export default ErrorBoundary;