import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckCircle, 
  faExclamationCircle, 
  faInfoCircle, 
  faTimesCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

const variants = {
  success: {
    icon: faCheckCircle,
    classes: 'bg-teal-500/20 border-teal-500/30 text-teal-400',
  },
  error: {
    icon: faTimesCircle,
    classes: 'bg-red-500/20 border-red-500/30 text-red-400',
  },
  warning: {
    icon: faExclamationCircle,
    classes: 'bg-yellow-500/20 border-yellow-500/30 text-yellow-400',
  },
  info: {
    icon: faInfoCircle,
    classes: 'bg-blue-500/20 border-blue-500/30 text-blue-400',
  },
};

const Toast = ({
  type = 'info',
  title,
  message,
  duration = 5000,
  onClose,
  className = '',
}) => {
  useEffect(() => {
    if (duration !== Infinity) {
      const timer = setTimeout(onClose, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  const { icon, classes } = variants[type];

  return (
    <motion.div
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      className={`
        flex items-start p-4 rounded-xl border backdrop-blur-lg
        shadow-lg
        ${classes}
        ${className}
      `}
    >
      <div className="flex-shrink-0">
        <FontAwesomeIcon icon={icon} className="h-5 w-5" />
      </div>
      <div className="ml-3 flex-1">
        {title && (
          <h3 className="text-sm font-medium">{title}</h3>
        )}
        {message && (
          <p className="mt-1 text-sm opacity-90">{message}</p>
        )}
      </div>
      <button
        onClick={onClose}
        className="ml-4 flex-shrink-0 inline-flex opacity-75 hover:opacity-100 transition-opacity"
      >
        <FontAwesomeIcon icon={faTimes} className="h-4 w-4" />
      </button>
    </motion.div>
  );
};

Toast.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  title: PropTypes.string,
  message: PropTypes.string,
  duration: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

// Toast Container Component
export const ToastContainer = ({ children }) => {
  return (
    <div className="fixed bottom-0 right-0 z-50 p-4 space-y-4 max-w-md w-full pointer-events-none">
      <AnimatePresence>
        {React.Children.map(children, (child) => (
          <div className="pointer-events-auto">
            {child}
          </div>
        ))}
      </AnimatePresence>
    </div>
  );
};

ToastContainer.propTypes = {
  children: PropTypes.node,
};

export default Toast;
